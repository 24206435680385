import React from 'react';
import { TextField } from '@material-ui/core';
import { LANG } from '../lang/lang';

interface Props {
    defaultValue: number;
    onChange: any;
    min: number;
    max: number;
}

interface State {
    error: boolean;
    value: string;
}

export class NumberTextbox extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            value: props.defaultValue
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue != this.state.value) {
            this.setState({
                value: nextProps.defaultValue
            })
        }
    }

    onChange(event) {
        const num = parseInt(event.target.value);
        if (isNaN(num) || num < this.props.min || num > this.props.max) {
            this.setState({
                error: true,
                value: event.target.value
            });
        } else {
            this.setState({
                error: false,
                value: "" + num
            });

            this.props.onChange(num);
        }
    }

    render() {
        return <TextField 
            value={this.state.value}
            error={this.state.error}
            helperText={this.state.error ? LANG["INVALID_NUMBER"] : null}
            onChange={this.onChange.bind(this)} />
    }
}