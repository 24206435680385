import React from 'react';

interface FlexProps {
    style?: React.CSSProperties;
    fullSize?: boolean;
    fullSizeOtherAxis?: boolean;
}

const createFlexStyle = (flex: string, props: FlexProps): React.CSSProperties => {
    let style = {
        display: "flex",
        flexFlow: flex,
        ...props.style
    };

    if (props.fullSize) {
        if (flex == "row") {
            style.width = "100%";
        } else {
            style.height = "100%";   
        }
    }

    if (props.fullSizeOtherAxis) {
        if (flex == "row") {
            style.height = "100%";
        } else {
            style.width = "100%";   
        }
    }

    return style;
}

export class FlexRow extends React.Component<FlexProps> {
    render() {
        return <div style={createFlexStyle("row", this.props)}>
            { this.props.children }
        </div>;
    }
}

export class FlexColumn extends React.Component<FlexProps> {
    render() {
        return <div style={createFlexStyle("column", this.props)}>
            { this.props.children }
        </div>;
    }
}

interface ItemFlexProps {
    style?: React.CSSProperties;
}

export class FlexItemAutoSize extends React.Component<ItemFlexProps> {
    render() {
        return <div style={{flex: "1 1 auto", ...this.props.style}}>
            { this.props.children }
        </div>;
    }
}