const lang_en = {
    // App stuff
    APP_INIT_USB_NOT_SUPPORTED: "This browser does not support USB connections!",
    APP_VER: "MIDI Pedal Configurator (Web) V1.0.0",
    
    // Generic
    CANCEL: "Cancel",
    EXIT: "Disconnect",

    DEVICE_LOST: "Lost connection to device!",

    // Connect screen
    CONNECT_TITLE: "Connect to a device",
    CONNECT_NODEVICES: "No devices found!",
    CONNECT_PROGRESS: "Connecting...",
    CONNECT_ERROR: "Failed to connect",
    CONNECT_HINT: "Click to connect",
    CONNECT_HINT2: "A browser window will appear to confirm device selection.",

    // Input info card
    INPUT_HEADER: "Inputs",

    // Input settings card
    INPUT_SETTINGS_SELECT: "Select an input to configure",

    INPUT_SETTINGS_PANEL1: "Input detection",
    INPUT_SETTINGS_PANEL1_ENMODE: "Enable mode:",

    INPUT_SETTINGS_PANEL2: "MIDI settings",
    INPUT_SETTINGS_PANEL2_MIDIMODE: "MIDI mode:",
    INPUT_SETTINGS_PANEL2_MIDICH: "MIDI channel:",
    INPUT_SETTINGS_PANEL2_PEDAL_NUM: "Pedal number:",
    INPUT_SETTINGS_PANEL2_KEY_PITCH: "Key pitch:",
    INPUT_SETTINGS_PANEL2_KEY_VELO: "Key velocity:",
    INPUT_SETTINGS_PANEL2_KEY_THRESHOLD: "Key threshold:",

    INPUT_SETTINGS_PANEL3: "Input range",
    INPUT_SETTINGS_PANEL3_INRANGE: "Range:",

    INPUT_SETTINGS_PANEL4: "Input function",
    INPUT_SETTINGS_PANEL4_TXMODE: "Math function:",

    INPUT_SETTINGS_PANEL5: "Output range",

    INPUT_SETTINGS_SAVE: "Save changes",

    // Input config consts
    INPUT_ENABLE_DETECT: "Detect",
    INPUT_ENABLE_ON: "Always ON",
    INPUT_ENABLE_OFF: "Always OFF",

    INPUT_MODE_DETAIL: "{0} at channel {1}",
    INPUT_MODE_CONTROL: "Pedal control",
    INPUT_MODE_KEY: "Key control",

    INPUT_TXMODE_LINEAR: "Linear",
    INPUT_TXMODE_LOG: "Logarithmic",
    INPUT_TXMODE_LOG_INV: "Inverse logarithmic",

    // Error messages
    INVALID_NUMBER: "Invalid number!",

    // Snackbac
    SAVE_OK: "Saved!",
    SAVE_ERROR: "Failed to save!",

    RESET_SETTINGS: "Reset all settings to default.",

    DEFAULT_DIALOG_TITLE: "Reset settings to default",
    DEFAULT_DIALOG_CONTENT: "Do you want to reset all settings to default?",
    DEFAULT_DIALOG_YES: "Yes",
    DEFAULT_DIALOG_NO: "No"
};

export const LANG = lang_en;

export const langFormat = (lang: string, params: any[]) => {
    params.forEach((val, index) => {
        lang = lang.replace(`{${index}}`, val);
    });

    return lang;
}

export const InputEnableValues = [ LANG["INPUT_ENABLE_DETECT"], LANG["INPUT_ENABLE_ON"], LANG["INPUT_ENABLE_OFF"] ]
export const InputModeValues = [ LANG["INPUT_MODE_CONTROL"], LANG["INPUT_MODE_KEY"] ]
export const InputTxMode = [ LANG["INPUT_TXMODE_LINEAR"], LANG["INPUT_TXMODE_LOG"], LANG["INPUT_TXMODE_LOG_INV"] ]