import React from 'react';
import { Card, Snackbar, IconButton, Icon, Typography, Button } from '@material-ui/core';
import { InputSliders } from './InputSliders';
import { LANG } from '../lang/lang';
import { InputSettings } from './InputSettings';
import { midiapp } from '../app/MidiApp';
import App from '../App';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Frame } from "../App";
import { InputConfig, MidiDeviceConfig } from '../app/MidiDevice';

interface Props {
    app: App;
}

interface State {
    selectedInput?: number
    selectedInputData?: InputConfig;
    inputStates: InputState[];

    snackbarOpen: boolean;
    snackbarError: boolean;
    snackbarTextKey: string;
}

export interface InputState {
    rawInput: number;
    transformed: number;
    actual: number;
}

export class AppConfig extends React.Component<Props, State> {
    inputSettingsRef;

    constructor(props) {
        super(props);

        this.inputSettingsRef = null;

        this.state = {
            selectedInput: null,
            selectedInputData: null,
            inputStates: null,

            snackbarOpen: false,
            snackbarError: false,
            snackbarTextKey: null
        };
    }

    componentWillMount() {
        let states: InputState[] = [];
        for (var i = 0; i < midiapp.device.inputCount; i++) {
            states.push({
                rawInput: 0,
                transformed: 0,
                actual: 0
            });
        }

        this.setState({
            inputStates: states
        });

        midiapp.setCallback(((data) => {
            if (data.Message == "cc") {
                const content = data.Data;

                const index = content.index;
                const change: InputState = {
                    rawInput: content.raw,
                    transformed: content.tx,
                    actual: content.real
                };

                let array = [ ...this.state.inputStates ];
                array[index] = change;
                this.setState({
                    inputStates: array
                }, () => {
                    if (this.inputSettingsRef != null && this.state.selectedInput === index) {
                        this.inputSettingsRef.handleInputStateChange(change);
                    }
                });
            } else if (data.Message == "config") {
                midiapp.device.config = data.Data as MidiDeviceConfig;
            } else if (data.Message == "device_lost") {
                this.props.app.setFrame(Frame.SelectDevice_Lost);
            }
        }).bind(this));
    }

    onInputSelect(index: number) {
        this.setState({
            selectedInput: index,
            selectedInputData: midiapp.device.config.Inputs[index]
        }, () => {
            if (this.inputSettingsRef != null && this.state.selectedInput === index) {
                this.inputSettingsRef.handleInputStateChange(this.state.inputStates[index]);
            }
        });
    }

    onSave() {
        midiapp.saveInputInfo(midiapp.device.config).then((result: boolean) => {
            if (result) {
                this.setState({
                    snackbarOpen: true,
                    snackbarError: false,
                    snackbarTextKey: "SAVE_OK"
                });
            } else {
                this.setState({
                    snackbarOpen: true,
                    snackbarError: true,
                    snackbarTextKey: "SAVE_ERROR"
                });
            }
        });
    }

    render() {
        const inputPanelWidth = `${80*2}px`;

        return <div style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%", display: "flex", flexFlow: "row"}}>
            <div style={{flex: `0 0 ${inputPanelWidth}px`, padding: "10px", display: "flex", flexFlow: "column" }}>
                <InputSliders
                    inputStates={this.state.inputStates}
                    onInputSelected={this.onInputSelect.bind(this)}
                />
                <Card elevation={3} style={{marginTop: "10px" }}>
                    <div style={{paddingTop: "7px", paddingBottom: "7px", width: "100%", height: "100%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}
                        onClick={() => {
                            midiapp.disconnect().then(() => {
                                this.props.app.setFrame(Frame.SelectDevice);
                            });
                        }}>
                        <Icon>
                            <ArrowBackIcon />
                        </Icon>
                        <Typography variant="h6">{LANG["EXIT"]}</Typography>
                    </div>
                </Card>
            </div>
            <div style={{flex: "3 1 0", padding: "10px"}}>
                <InputSettings
                    ref={ref => this.inputSettingsRef = ref}
                    selectedInput={this.state.selectedInput}
                    selectedInputData={this.state.selectedInputData}
                    onSave={this.onSave.bind(this)}
                />
            </div>
            <Snackbar open={this.state.snackbarOpen} autoHideDuration={2000} onClose={() => {
                this.setState({
                    snackbarOpen: false
                })
            }}>
                <MuiAlert severity={this.state.snackbarError ? "error" : "success"}>
                    {LANG[this.state.snackbarTextKey]}
                </MuiAlert>
            </Snackbar>
        </div>;
    }
}
