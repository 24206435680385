import React from 'react';
import { Typography, Card, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton, Select, MenuItem, Divider, Slider, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch } from '@material-ui/core';
import { LANG, langFormat } from '../lang/lang';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputEnableValues, InputModeValues, InputTxMode } from '../lang/lang'
import { NumberTextbox } from "./NumberTextbox";
import styled from "styled-components";
import { MinMaxSlider } from './MinMaxSlider';
import SaveIcon from '@material-ui/icons/Save';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MidiDevice } from '../app/MidiDevice';
import { InputState } from './AppConfig';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { midiapp } from '../app/MidiApp';

interface Props {
    selectedInput?: number;
    selectedInputData?: any;
    onSave: any;
}

interface State {
    selectedExpand?: string;
    data: any;

    defaultDialogOpen: boolean;
}

const TableTextColumn = styled.td`{
    width: 200px;
}`;

const TableContentColumn = styled.td`{
}`;

const saveButtonTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#00aa00"
        }
    }
});

export class InputSettings extends React.Component<Props, State> {
    markTransformed;
    markRawInput;

    inputMinMaxRef;
    outputMinMaxRef;

    constructor(props) {
        super(props);

        this.markTransformed = null;
        this.markRawInput = null;

        this.inputMinMaxRef = null;
        this.outputMinMaxRef = null;

        this.state = {
            selectedExpand: null,
            data: null,
            defaultDialogOpen: false
        };
    }

    handleChange(event, isExpanded, panel) {
        this.setState({
            selectedExpand: (isExpanded ? panel : null)
        })
    };

    setProperty(name: string, value: any) {
        this.setProperties([{
            name: name,
            value: value
        }]);
    }

    setProperties(what: any[]) {
        let obj = this.state.data;
        what.forEach(val => {
            obj[val.name] = val.value;
        });
        this.setState({
            data: {
                ...obj
            }
        }, () => {
            Object.assign(this.props.selectedInputData, this.state.data);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedInputData != this.state.data) {
            this.setState({
                data: nextProps.selectedInputData
            }, () => {
                if (this.inputMinMaxRef != null) {
                    this.inputMinMaxRef.setData(this.state.data.InputMin, this.state.data.InputMax);
                }
                if (this.outputMinMaxRef != null) {
                    this.outputMinMaxRef.setData(this.state.data.OutMin, this.state.data.OutMax);
                }
            })
        }
    }

    handleInputStateChange(data: InputState) {
        if (this.markTransformed != null) {
            this.markTransformed.onChange(data);
        }
        if (this.markRawInput != null) {
            this.markRawInput.onChange(data);
        }
    }

    createPitchMenuItems() {
        let list = [];

        const PITCH_MIN = 21;
        const PITCH_MAX = 108;
        const texts = [ "A0", "A#0", "B0",
            "C1", "C#1", "D1", "D#1", "E1", "F1", "F#1", "G1", "G#1", "A1", "A#1", "B1",
            "C2", "C#2", "D2", "D#2", "E2", "F2", "F#2", "G2", "G#2", "A2", "A#2", "B2",
            "C3", "C#3", "D3", "D#3", "E3", "F3", "F#3", "G3", "G#3", "A3", "A#3", "B3",
            "C4", "C#4", "D4", "D#4", "E4", "F4", "F#4", "G4", "G#4", "A4", "A#4", "B4",
            "C5", "C#5", "D5", "D#5", "E5", "F5", "F#5", "G5", "G#5", "A5", "A#5", "B5", 
            "C6", "C#6", "D6", "D#6", "E6", "F6", "F#6", "G6", "G#6", "A6", "A#6", "B6",
            "C7", "C#7", "D7", "D#7", "E7", "F7", "F#7", "G7", "G#7", "A7", "A#7", "B7",
            "C8"];
        for (let pitch = PITCH_MIN; pitch <= PITCH_MAX; pitch++) {
            list.push({
                value: pitch,
                text: texts[pitch - PITCH_MIN]
            })
        }

        return list;
    }

    render() {
        if (this.props.selectedInput == null || this.state.data == null) {
            return <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h5">{LANG["INPUT_SETTINGS_SELECT"]}</Typography>
            </div>;
        }

        return <Card elevation={3} style={{ width: "100%", height: "100%", padding: "10px" }}>
            <div style={{ width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden", position: "relative" }}>
                <ExpansionPanel expanded={this.state.selectedExpand === "panel1"} onChange={(event, state) => this.handleChange(event, state, "panel1")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" style={{ flexShrink: 0, flexBasis: "33%" }}>{LANG["INPUT_SETTINGS_PANEL1"]}</Typography>
                        <Typography variant="h6" color="secondary">{InputEnableValues[this.state.data.Enable]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <table>
                            <tbody>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL1_ENMODE"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <Select style={{ minWidth: "300px" }} value={this.state.data.Enable} onChange={(event) => { this.setProperty("Enable", event.target.value); }}>
                                            {
                                                InputEnableValues.map((name, index) => {
                                                    return <MenuItem key={index} value={index}>{name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </TableContentColumn>
                                </tr>
                            </tbody>
                        </table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.selectedExpand === "panel2"} onChange={(event, state) => this.handleChange(event, state, "panel2")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" style={{ flexShrink: 0, flexBasis: "33%" }}>{LANG["INPUT_SETTINGS_PANEL2"]}</Typography>
                        <Typography variant="h6" color="secondary">{langFormat(LANG["INPUT_MODE_DETAIL"], [InputModeValues[this.state.data.Mode], this.state.data.MidiChannel + 1])}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_MIDIMODE"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <Select style={{ minWidth: "300px" }} value={this.state.data.Mode} onChange={(event) => { this.setProperty("Mode", event.target.value); }}>
                                            {
                                                InputModeValues.map((name, index) => {
                                                    return <MenuItem key={index} value={index}>{name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </TableContentColumn>
                                </tr>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_MIDICH"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <NumberTextbox min={1} max={16} defaultValue={this.state.data.MidiChannel + 1} onChange={(num) => { this.setProperty("MidiChannel", num - 1); }} />
                                    </TableContentColumn>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Divider style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }} />
                                    </td>
                                </tr>
                                {
                                    this.state.data.Mode == 0 ?
                                        (
                                            <tr>
                                                <TableTextColumn>
                                                    <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_PEDAL_NUM"]}</Typography>
                                                </TableTextColumn>
                                                <TableContentColumn>
                                                    <NumberTextbox min={0} max={127} defaultValue={this.state.data.Control} onChange={(num) => { this.setProperty("Control", num); }} />
                                                </TableContentColumn>
                                            </tr>
                                        ) : null
                                }
                                {
                                    this.state.data.Mode == 1 ?
                                        (
                                            <React.Fragment>
                                                <tr>
                                                    <TableTextColumn>
                                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_KEY_PITCH"]}</Typography>
                                                    </TableTextColumn>
                                                    <TableContentColumn>
                                                        <Select 
                                                            value={this.state.data.KeyPitch}
                                                            onChange={(event) => { this.setProperty("KeyPitch", event.target.value); }}>
                                                            { 
                                                                this.createPitchMenuItems().map(({ value, text }) => <MenuItem key={value} value={value}>{text}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </TableContentColumn>
                                                </tr>
                                                <tr>
                                                    <TableTextColumn>
                                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_KEY_VELO"]}</Typography>
                                                    </TableTextColumn>
                                                    <TableContentColumn>
                                                        <NumberTextbox min={0} max={127} defaultValue={this.state.data.KeyVelocity} onChange={(num) => { this.setProperty("KeyVelocity", num); }} />
                                                    </TableContentColumn>
                                                </tr>
                                                <tr>
                                                    <TableTextColumn>
                                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL2_KEY_THRESHOLD"]}</Typography>
                                                    </TableTextColumn>
                                                    <TableContentColumn>
                                                        <NumberTextbox min={0} max={127} defaultValue={this.state.data.KeyThreshold} onChange={(num) => { this.setProperty("KeyThreshold", num); }} />
                                                    </TableContentColumn>
                                                </tr>
                                            </React.Fragment>
                                        ) : null
                                }
                            </tbody>
                        </table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.selectedExpand === "panel3"} onChange={(event, state) => this.handleChange(event, state, "panel3")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{LANG["INPUT_SETTINGS_PANEL3"]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL3_INRANGE"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <MinMaxSlider ref={ref => this.inputMinMaxRef = ref} min={0} max={4095} onChange={(values) => {
                                            this.setProperties([{
                                                name: "InputMin",
                                                value: values[0]
                                            }, {
                                                name: "InputMax",
                                                value: values[1]
                                            }])
                                        }} />
                                    </TableContentColumn>
                                </tr>
                                <tr>
                                    <TableTextColumn>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <SliderWrapper ref={ref => this.markRawInput = ref} min={0} max={4095} propName={"rawInput"} />
                                    </TableContentColumn>
                                </tr>
                            </tbody>
                        </table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.selectedExpand === "panel4"} onChange={(event, state) => this.handleChange(event, state, "panel4")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" style={{ flexShrink: 0, flexBasis: "33%" }}>{LANG["INPUT_SETTINGS_PANEL4"]}</Typography>
                        <Typography variant="h6" color="secondary">{InputTxMode[this.state.data.InputTransform]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px", whiteSpace: "nowrap" }}>{LANG["INPUT_SETTINGS_PANEL4_TXMODE"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <Select style={{ minWidth: "300px" }} value={this.state.data.InputTransform} onChange={(event) => { this.setProperty("InputTransform", event.target.value); }}>
                                            {
                                                InputTxMode.map((name, index) => {
                                                    return <MenuItem key={index} value={index}>{name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </TableContentColumn>
                                </tr>
                            </tbody>
                        </table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.selectedExpand === "panel5"} onChange={(event, state) => this.handleChange(event, state, "panel5")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{LANG["INPUT_SETTINGS_PANEL5"]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <TableTextColumn>
                                        <Typography variant="h6" style={{ marginRight: "10px" }}>{LANG["INPUT_SETTINGS_PANEL3_INRANGE"]}</Typography>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <MinMaxSlider ref={ref => this.outputMinMaxRef = ref} min={0} max={255} onChange={(values) => {
                                            this.setProperties([{
                                                name: "OutMin",
                                                value: values[0]
                                            }, {
                                                name: "OutMax",
                                                value: values[1]
                                            }])
                                        }} />
                                    </TableContentColumn>
                                </tr>
                                <tr>
                                    <TableTextColumn>
                                    </TableTextColumn>
                                    <TableContentColumn>
                                        <SliderWrapper color="secondary" ref={ref => this.markTransformed = ref} min={0} max={255} propName={"transformed"} />
                                    </TableContentColumn>
                                </tr>
                            </tbody>
                        </table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
                    <div style={{ flex: "1 1 auto" }}>
                        <Tooltip title={LANG["RESET_SETTINGS"]}>
                            <IconButton onClick={(() => {
                                    this.setState({
                                        defaultDialogOpen: true
                                    })
                                }).bind(this)}>
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ThemeProvider theme={saveButtonTheme}>
                        <Button style={{ float: "right" }} startIcon={<SaveIcon />} color="primary" variant="contained"
                            onClick={() => {
                                this.props.onSave();
                            }}>{LANG["INPUT_SETTINGS_SAVE"]}</Button>
                    </ThemeProvider>
                </div>
            </div>

            <Dialog
                open={this.state.defaultDialogOpen}
                onClose={(() => {
                    this.setState({
                        defaultDialogOpen: false
                    })
                }).bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{LANG["DEFAULT_DIALOG_TITLE"]}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{LANG["DEFAULT_DIALOG_CONTENT"]}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(() => {
                        this.setState({
                            defaultDialogOpen: false
                        })
                    }).bind(this)} color="primary">
                        {LANG["DEFAULT_DIALOG_NO"]}
                    </Button>
                    <Button onClick={(() => {
                        midiapp.resetSettings();

                        this.setState({
                            defaultDialogOpen: false
                        })
                    }).bind(this)} color="primary" autoFocus>
                        {LANG["DEFAULT_DIALOG_YES"]}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    }
}

interface SliderWrapperProps {
    min: number;
    max: number;
    propName: string;
    color?: "primary" | "secondary";
}

interface SliderWrapperState {
    inputState: InputState;
}

class SliderWrapper extends React.Component<SliderWrapperProps, SliderWrapperState> {
    constructor(props) {
        super(props);

        this.state = {
            inputState: null
        }
    }

    onChange(data: InputState) {
        this.setState({
            inputState: data
        });
    }

    render() {
        return <Slider color={this.props.color || "primary"} track={false} min={this.props.min} max={this.props.max} value={this.state.inputState == null ? 0 : this.state.inputState[this.props.propName]} />;
    }
}