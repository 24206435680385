import React from 'react';
import { Slider } from '@material-ui/core';

interface Props {
    min: number;
    max: number;

    onChange: any;
}

interface State {
    min: number;
    max: number;
}

export class MinMaxSlider extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            min: props.valueMin,
            max: props.valueMax
        }
    }

    setData(valueMin: number, valueMax: number) {
        this.setState({
            min: valueMin,
            max: valueMax
        });
    }

    render() {
        return <Slider min={this.props.min} max={this.props.max} value={[this.state.min, this.state.max]} onChange={(event, value) => {
            this.setState({
                min: value[0],
                max: value[1]
            });
        }}
        onChangeCommitted={(event, value) => {
            this.props.onChange(value);
        }} />;
    }
}