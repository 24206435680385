import { InputConfig, InputEnable, InputMode, InputTransform, MidiDevice, MidiDeviceConfig } from "./MidiDevice";

const COMMAND_REQUEST_HARDWAREINFO = 0x2A;
const COMMAND_SENDING_HARDWAREINFO = 0x2F;
const COMMAND_CONTROL_CHANGE = 0x3F;
const COMMAND_REQUEST_CONFIG = 0x4A;
const COMMAND_SENDING_CONFIG = 0x4F;
const COMMAND_REQUEST_INPUT_STATES = 0x5F;
const COMMAND_RESET_CONFIG = 0x6F;

type MessageType = {
    Message: string;
    Data: any;
};

const process_hwinfo = (reader: BufferReaderHelper, device: MidiDevice): MessageType => {
    const ver = reader.readUInt16();
    const inputCount = reader.readByte();

    const data = {
        version: ver,
        input_count: inputCount
    };

    return {
        Message: "hw_info",
        Data: data
    };
};

const process_cc = (reader: BufferReaderHelper, device: MidiDevice): MessageType => {
    const inputIndex = reader.readByte();
    const rawInput = reader.readUInt16();
    const midiOut = reader.readByte();
    const actualOut = reader.readByte();

    const cc = {
        index: inputIndex,
        raw: rawInput,
        tx: midiOut,
        real: actualOut
    };

    return {
        Message: "cc",
        Data: cc
    };
};

const process_config = (reader: BufferReaderHelper, device: MidiDevice): MessageType => {
    const refreshRate = reader.readUInt16();
    const jitterFix = reader.readByte();

    const inputs: InputConfig[] = [];
    for (let inputIndex = 0; inputIndex < device.inputCount; inputIndex++) {
        const enable = reader.readByte();
        
        const mode = reader.readByte();
        const midiChannel = reader.readByte();

        const inputMin = reader.readUInt16();
        const inputMax = reader.readUInt16();
        const transform = reader.readByte();
        const outMin = reader.readByte();
        const outMax = reader.readByte();

        const control = reader.readByte();

        const pitch = reader.readByte();
        const velocity = reader.readByte();
        const threshold = reader.readByte();

        inputs.push({
            Enable: enable as InputEnable,

            Mode: mode as InputMode,
            MidiChannel: midiChannel,

            InputMin: inputMin,
            InputMax: inputMax,
            InputTransform: transform as InputTransform,
            OutMin: outMin,
            OutMax: outMax,

            Control: control,

            KeyPitch: pitch,
            KeyVelocity: velocity,
            KeyThreshold: threshold
        } as InputConfig);
    }

    const config: MidiDeviceConfig = {
        RefreshRate: refreshRate,
        JitterFix: jitterFix > 0,
        Inputs: inputs
    };

    return {
        Message: "config",
        Data: config
    };
};

const PACKET_PROCESSORS = {
    /* COMMAND_SENDING_HARDWAREINFO */ 0x2F: process_hwinfo,
    /* COMMAND_CONTROL_CHANGE */ 0x3F: process_cc,
    /* COMMAND_SENDING_CONFIG */ 0x4F: process_config
};

class MidiApp {
    public device: MidiDevice = undefined;

    callback;
    serialDevice = undefined;
    writer = undefined;
    reader = undefined;

    // Protocol stuff
    private sendCommand(command: number) {
        let cmdBuff = [command, 7, 0, 0, 0, 0, 0];
        
        const crc = CRC32.calculate(cmdBuff);
        cmdBuff[3] = (crc & 0xFF) >>> 0;
        cmdBuff[4] = ((crc & 0xFF00) >>> 8) >>> 0;
        cmdBuff[5] = ((crc & 0xFF0000) >>> 16) >>> 0;
        cmdBuff[6] = ((crc & 0xFF000000) >>> 24) >>> 0;

        const data = new Uint8Array(cmdBuff);
        return this.writer.write(data);
    }

    private packetProcessLoop() {
        try {
            setTimeout(async () => {
                try {
                    const { value, done } = await this.reader.read();
                    if (done) {
                        return;
                    }
    
                    const action = value[0];
                    const length = (value[1] | (value[2] << 8)) >>> 0;
                    const crc32 = (value[3] | (value[4] << 8) | (value[5] << 16) | (value[6] << 24)) >>> 0;
                    
                    for (let x = 3; x < 7; x++) value[x] = 0;
                    const calcCrc = CRC32.calculate(value);
        
                    if (crc32 == calcCrc) {
                        if (PACKET_PROCESSORS[action] !== undefined) {
                            const helper = new BufferReaderHelper(value, 7);
    
                            const data = PACKET_PROCESSORS[action](helper, this.device);
    
                            if (this.callback !== undefined) {
                                this.callback(data);
                            }
                        }
                    }
    
                    // Start next loop
                    this.packetProcessLoop();
                } catch (err) {
                    if (err.code === 19) {
                        // Device lost
                        this.onDeviceLost();
                    }
                    console.log(err);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    // Receive callback
    public setCallback(cb: (message: MessageType) => void) {
        this.callback = cb;
    }

    private onDeviceLost() {
        if (this.callback !== undefined) {
            this.callback({
                Message: "device_lost",
                Data: undefined
            });
        }
    }

    // Commands
    private getHwInfo() {
        return this.sendCommand(COMMAND_REQUEST_HARDWAREINFO);
    }

    async connectToDevice(portDevice: any) {
        return new Promise((resolve, reject) => {
            const doConnect = () => {
                this.serialDevice = portDevice;

                portDevice.open({
                    baudRate: 9600,
                    dataBits: 8,
                    stopBits: 1,
                    parity: "none",
                    flowControl: "none"
                })
                .then(() => {
                    return portDevice.setSignals({
                        dataTerminalReady: true,
                        requestToSend: true
                    });
                })
                .then(() => {
                    this.writer = portDevice.writable.getWriter();
                    this.reader = portDevice.readable.getReader();

                    // Start the packet processing loop
                    this.packetProcessLoop();

                    return this.getHwInfo();
                })
                .then(() => {
                    return resolve(true);
                })
                .catch(err => {
                    console.error(err);
                    this.disconnect().then(() => {
                        resolve(false);
                    });
                });
            }

            if (this.serialDevice !== undefined) {
                this.disconnect().then(() => {
                    doConnect();
                })
                .catch(err => {
                    console.error(err);
                    doConnect();
                })
            } else {
                doConnect();
            }
        });
    }

    async getInputInfo() {
        return this.sendCommand(COMMAND_REQUEST_CONFIG);
    }

    async saveInputInfo(config: MidiDeviceConfig) {
        return new Promise((resolve, reject) => {
            let packet: number[] = [ COMMAND_SENDING_CONFIG, 0, 0, 0, 0, 0, 0 ]; // Action + length + crc32
            const writer: BufferWriterHelper = new BufferWriterHelper(packet);

            writer.writeUInt16(config.RefreshRate);
            writer.writeByte(config.JitterFix ? 1 : 0);

            for (let inputIndex = 0; inputIndex < config.Inputs.length; inputIndex++) {
                const input = config.Inputs[inputIndex];

                writer.writeByte(input.Enable as number);
                
                writer.writeByte(input.Mode as number);
                writer.writeByte(input.MidiChannel);

                writer.writeUInt16(input.InputMin);
                writer.writeUInt16(input.InputMax);
                writer.writeByte(input.InputTransform as number);
                writer.writeByte(input.OutMin);
                writer.writeByte(input.OutMax);

                writer.writeByte(input.Control as number);

                writer.writeByte(input.KeyPitch);
                writer.writeByte(input.KeyVelocity);
                writer.writeByte(input.KeyThreshold);
            }

            packet[1] = ((packet.length & 0xFF) >>> 0);
            packet[2] = (((packet.length & 0xFF00) >>> 8) >>> 0);

            const crc = CRC32.calculate(packet);
            packet[3] = (crc & 0xFF) >>> 0;
            packet[4] = ((crc & 0xFF00) >>> 8) >>> 0;
            packet[5] = ((crc & 0xFF0000) >>> 16) >>> 0;
            packet[6] = ((crc & 0xFF000000) >>> 24) >>> 0;

            const data = new Uint8Array(packet);
            this.writer.write(data)
            .then(() => resolve(true))
            .catch(err => {
                console.error(err);
                resolve(false);
            });
        });
    }

    async resetSettings() {
        return this.sendCommand(COMMAND_RESET_CONFIG);
    }

    async disconnect() {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.serialDevice !== undefined) {
                    await this.writer.releaseLock();
                    try {
                        await this.reader.cancel();
                    } catch (err) {
                        if (err.code !== 19) { // Ignore device lost errors
                            console.error(err);
                        }
                    }
                    await this.reader.releaseLock();
                    await this.serialDevice.close();
                    this.serialDevice = undefined;
                    this.writer = undefined;
                    this.reader = undefined;
                }
                this.device = undefined;
                resolve(null);
            } catch (err) {
                console.error(err);
                reject(err);
            }
        });
    }
}

class BufferReaderHelper {
    buffer;
    index;

    constructor(buffer, index) {
        this.buffer = buffer;
        this.index = index;
    }

    public readByte() {
        const value = this.buffer[this.index] >>> 0;
        this.index += 1;

        return value;
    }

    public readUInt16() {
        const value = (this.buffer[this.index] | (this.buffer[this.index + 1] << 8)) >>> 0;
        this.index += 2;

        return value;
    }
}

class BufferWriterHelper {
    buffer: number[];

    constructor (b) {
        this.buffer = b;
    }

    public writeByte(b: number) {
        this.buffer.push(b >>> 0);
    }

    public writeUInt16(s: number) {
        this.buffer.push((s & 0xFF) >>> 0);
        this.buffer.push(((s & 0xFF00) >>> 8) >>> 0);
    }
}

class CRC32 {
    static table: number[] = undefined;

    static tableInit(r: number) {
        for (let j = 0; j < 8; j++) {
            r = (((r & 1) == 1 ? 0 : 0xEDB88320) ^ r >>> 1) >>> 0;
        }
        return (r ^ 0xFF000000) >>> 0;
    }

    static calculate(data: number[]) {
        if (CRC32.table == undefined) {
            CRC32.table = [];
            for (let i = 0; i < 0x100; i++) {
                CRC32.table[i] = CRC32.tableInit(i);
            }
        }

        let crc = 0;
        for (let j = 0; j < data.length; j++) {
            crc = (CRC32.table[(crc & 0xFF) ^ data[j]] ^ crc >>> 8) >>> 0;
        }
        return crc;
    }
}

export const midiapp = new MidiApp();