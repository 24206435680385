import React from 'react';
import { Slider, Card, Typography } from '@material-ui/core';
import { LANG } from '../lang/lang';
import { FlexColumn, FlexItemAutoSize, FlexRow } from './Flex';
import { MidiDevice } from '../app/MidiDevice';
import { InputState } from './AppConfig';

interface InputComponentProps {
    index: number;
    selected?: boolean;
    onSelected: any;
    state: InputState;
}

const COLOR_SELECTED = "#616161";
const COLOR_UNSELECTED = "#515151";
class InputComponent extends React.Component<InputComponentProps> {
    onClick() {
        this.props.onSelected();
    }

    render() {
        return <Card style={{marginLeft: "5px", marginRight: "5px", paddingTop: "10px", backgroundColor: (this.props.selected ? COLOR_SELECTED : COLOR_UNSELECTED), cursor: "pointer"}} onClick={this.onClick.bind(this)}>
            <FlexColumn fullSize style={{textAlign: "center"}}>
                <Slider 
                    orientation="vertical"
                    value={this.props.state.actual}
                    min={0}
                    max={255}
                    style={{marginBottom: "10px", marginLeft: "10px", marginRight: "10px"}} />
                <Typography>#{this.props.index+1}</Typography>
            </FlexColumn>
        </Card>;
    }
}

interface Props {
    onInputSelected: any;
    inputStates: InputState[];
}

interface State {
    selectedInput?: number;
}

export class InputSliders extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedInput: null
        };
    }

    onSliderSelect(index: number) {
        this.setState({
            selectedInput: index
        }, () => {
            this.props.onInputSelected(index);
        });
    }

    render() {
        return <Card elevation={3} style={{width: "100%", height: "100%", padding: "15px" }}>
            <FlexColumn fullSize>
                <Typography style={{textAlign: "center" }} variant="h6">{LANG["INPUT_HEADER"]}</Typography>
                <FlexItemAutoSize style={{padding: "5px", paddingTop: "10px"}}>
                    <FlexRow fullSize fullSizeOtherAxis style={{justifyContent: "center" }}>
                        {
                            this.props.inputStates.map((val, index) => {
                                return <InputComponent key={index} state={val} index={index} selected={this.state.selectedInput === index} onSelected={() => this.onSliderSelect(index)} />
                            })
                        }
                    </FlexRow>
                </FlexItemAutoSize>
            </FlexColumn>
        </Card>
    }
}