import React from 'react';
import { CircularProgress } from '@material-ui/core';

export class FullscreenLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>;
    }
}