import React from 'react';
import { midiapp } from './app/MidiApp';
import { FullscreenLoader } from './components/FullscreenLoader';
import { FullscreenError } from './components/FullscreenError';
import { If } from './components/If';
import { LANG } from './lang/lang';
import { AppConfig } from './components/AppConfig';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppSelectDevice } from './components/AppSelectDevice';
import { MidiDevice } from './app/MidiDevice';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: "#ffffff"
        },
        secondary: {
            main: "#aaaaaa"
        }
    },
    overrides: {
        MuiSlider: {
            track: {
                color: "white"
            },
            rail: {
                color: "white"
            }
        },
        MuiExpansionPanel: {
            root: {
                backgroundColor: "#616161"
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "white"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "white"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "1rem"
            }
        },
        MuiIcon: {
            fontSizeLarge: {
                fontSize: "4rem"
            }
        }
    }
});

export enum Frame {
    LoadingApp,
    SelectDevice,
    SelectDevice_Lost, // Device lost, select device
    Config
}

interface AppState {
    currentFrame: Frame;

    usbUnsupported: boolean;
    containerLoading: boolean;
}

export default class App extends React.Component<any, AppState> {
    constructor(props) {
        super(props);

        this.state = {
            currentFrame: Frame.LoadingApp,
            containerLoading: true,
            usbUnsupported: (navigator === undefined || (!("serial" in navigator)))
        };
    }

    public setFrame(f: Frame) {
        this.setState({
            currentFrame: f
        });
    }

    componentWillMount() {
        if (!this.state.usbUnsupported) {
            this.setState({
                containerLoading: false,
                currentFrame: Frame.SelectDevice
            });
        }
    }

    render() {
        return <ThemeProvider  theme={darkTheme}>
            <CssBaseline/>
            <div style={{width: "100%", height: "100%", position: "relative"}}>
                <If visible={this.state.currentFrame == Frame.LoadingApp}>
                    <If visible={this.state.usbUnsupported}>
                        <FullscreenError error={LANG["APP_INIT_USB_NOT_SUPPORTED"]} />
                    </If>
                    <If visible={this.state.containerLoading && !this.state.usbUnsupported}>
                        <FullscreenLoader />
                    </If>
                </If>
                <If visible={this.state.currentFrame == Frame.SelectDevice || this.state.currentFrame == Frame.SelectDevice_Lost}>
                    <AppSelectDevice app={this} lost={this.state.currentFrame == Frame.SelectDevice_Lost} />
                </If>
                <If visible={this.state.currentFrame == Frame.Config}>
                    <AppConfig app={this} />
                </If>
            </div>
        </ThemeProvider >;
    }
}
