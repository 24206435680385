import React from 'react';

interface IfProps {
    visible: boolean;
}

export class If extends React.Component<IfProps> {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        return <React.Fragment>
            { this.props.children }
        </React.Fragment>;
    }
}