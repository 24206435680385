import React from 'react';
import { Card, Typography, Divider, ListItem, ListItemIcon, ListItemText, List, IconButton, CircularProgress, Icon, Button, Snackbar } from '@material-ui/core';
import UsbIcon from '@material-ui/icons/UsbTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import { midiapp } from '../app/MidiApp';
import { If } from "./If";
import { LANG } from "../lang/lang";
import App from '../App';
import { Frame } from '../App';
import { MidiDevice } from '../app/MidiDevice';
import MuiAlert from '@material-ui/lab/Alert';

const USB_FILTER = [
    { usbVendorId: 0x1209, usbProductId: 0x51AF }
];

interface Props {
    app: App;
    lost: boolean;
}

interface State {
    connecting: boolean;
    connectionFail: boolean;

    showLost: boolean;
}

export class AppSelectDevice extends React.Component<Props, State> {
    connectShowTimeout;

    constructor(props: Props) {
        super(props);

        this.connectShowTimeout = null;

        this.state = {
            connecting: false,
            connectionFail: false,
            showLost: props.lost
        };
    }

    componentWillMount() {
        if (midiapp.device !== undefined) {
            midiapp.disconnect();
        }

        midiapp.setCallback((async (data) => {
            switch (data.Message) {
                case "hw_info": {
                    // Got hardware info
                    midiapp.device = new MidiDevice();
                    midiapp.device.version = data.Data.version;
                    midiapp.device.inputCount = data.Data.input_count;

                    // Get input info
                    await midiapp.getInputInfo();
                }
                break;

                case "config": {
                    midiapp.device.config = data.Data;
                    this.props.app.setFrame(Frame.Config);
                }
                break;
            }
        }).bind(this));
    }
    
    cancelConnect() {
        this.setState({
            connecting: false,
            connectionFail: false
        });
        midiapp.disconnect();
    }

    onConnect() {
        this.setState({
            connecting: true,
            connectionFail: false
        });

        const serial = (navigator as any).serial;
        serial.requestPort({
            filters: USB_FILTER
        })
        .then(port => {
            if (this.connectShowTimeout != null) {
                clearTimeout(this.connectShowTimeout);
                this.connectShowTimeout = null;
            }
    
            this.connectShowTimeout = setTimeout((() => {
                this.setState({
                    connecting: true,
                    connectionFail: true
                });
            }).bind(this), 1000);

            midiapp.connectToDevice(port).then((result: any) => {
                if (this.connectShowTimeout != null) {
                    clearTimeout(this.connectShowTimeout);
                    this.connectShowTimeout = null;
                }
    
                if (!result) {
                    this.setState({
                        connecting: true,
                        connectionFail: true
                    });
                }
            });
        })
        .catch(err => {
            console.error(err);
            this.setState({
                connecting: true,
                connectionFail: true
            });
        });
    }

    render() {
        return <div style={{ display: "flex", width: "100%", height: "100%", padding: "10px", justifyContent: "center", alignItems: "center" }}>
            <Card elevation={3} style={{ flex: "0 0 50%", maxWidth: "500px", height: "300px", padding: "10px", display: "flex", flexFlow: "column" }}>
                <div style={{ width: "100%", position: "relative" }}>
                    <Typography style={{ textAlign: "center" }} variant="h5">{LANG["CONNECT_TITLE"]}</Typography>
                </div>

                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />

                <div style={{ flex: "1 1 auto", overflowX: "hidden", overflowY: "auto" }}>
                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <If visible={!this.state.connecting}>
                            <div style={{ textAlign: "center", cursor: "pointer" }} onClick={this.onConnect.bind(this)}>
                                <Icon>
                                    <UsbIcon style={{fontSize: "100px"}} />
                                </Icon>
                                <Typography>{LANG["CONNECT_HINT"]}</Typography>
                                <Typography color="secondary">{LANG["CONNECT_HINT2"]}</Typography>
                            </div>
                        </If>
                        <If visible={this.state.connecting}>
                            {
                                this.state.connectionFail ?
                                    <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                                        this.setState({
                                            connecting: false,
                                            connectionFail: false
                                        })
                                    }}>
                                        <Icon fontSize="large">
                                            <ErrorIcon />
                                        </Icon>
                                        <Typography>{LANG["CONNECT_ERROR"]}</Typography>
                                        <Button variant="outlined" onClick={this.cancelConnect.bind(this)}>{LANG["CANCEL"]}</Button>
                                    </div>
                                    :
                                    <div style={{ textAlign: "center" }}>
                                        <CircularProgress />
                                        <Typography>{LANG["CONNECT_PROGRESS"]}</Typography>
                                        <Button variant="outlined" onClick={this.cancelConnect.bind(this)}>{LANG["CANCEL"]}</Button>
                                    </div>
                            }
                        </If>
                    </div>
                </div>
            </Card>

            <span style={{position:"absolute", bottom: 0, color: "#555555"}}>{LANG["APP_VER"]}</span>

            <Snackbar open={this.state.showLost} autoHideDuration={5000} onClose={() => {
                this.setState({
                    showLost: false
                })
            }}>
                <MuiAlert severity={"error"}>
                    {LANG["DEVICE_LOST"]}
                </MuiAlert>
            </Snackbar>
        </div>;
    }
}