export class MidiDevice {
    public version: number;
    public inputCount: number;
    public config: MidiDeviceConfig;
}

export class MidiDeviceConfig {
    public RefreshRate: number;
    public JitterFix: boolean;

    public Inputs: InputConfig[];
}

export enum InputEnable {
    DETECT = 0,
    ENABLED = 1,
    DISABLED = 2,
}

export enum InputMode {
    CONTROL = 0,
    KEY = 1,
}

export enum InputTransform {
    LINEAR = 0,
    LOG = 1,
    INV_LOG = 2
}

export class InputConfig {
    public Enable: InputEnable;

    public Mode: InputMode;
    public MidiChannel: number;

    public InputMin: number;
    public InputMax: number;
    public InputTransform: InputTransform;
    public OutMin: number;
    public OutMax: number;

    public Control: number;

    public KeyPitch: number;
    public KeyVelocity: number;
    public KeyThreshold: number;
}
