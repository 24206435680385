import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Icon, Typography } from '@material-ui/core';

interface Props {
    error: string;
}

export class FullscreenError extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{textAlign: "center"}}>
                <Icon fontSize="large">
                    <ErrorIcon />
                </Icon>
                <Typography>{this.props.error}</Typography>
            </div>
        </div>;
    }
}